import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "react-bootstrap";
import drugFileDownload from "../../../shared/csvDownload/drugFileDownload";
import GridComponent from "../../../../components/grid/gridComponent";
import { getTableFields } from "../../../../helpers/getTableFields";
import {
    COLUMN_CONFIG_REPORT_NAMES,
    formatDatePipe,
    gridAlignment,
    formateIds,
    visibleColumnNames

} from "../../../../interface/comman.constant";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import { useAppDispatch } from "../../../../store/hooks";
import { useSelector } from "react-redux";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { getRXRDOrderHistoryDetails } from "../../../../store/api/apiSlice";
export const RXRDOrderHistoryDetails = (props: any) => {
    const myRef = useRef<any>();
    const [gridApi, setGridApi] = useState<any>(null);
    const gridRef = useRef<AgGridReact>(null);
    const queryParams: any = useParams();
    const [partneredSummaries, setPartneredSummaries] = useState<any>(null);
    const { id, dbColumnConfig } = useUserSettings(
        COLUMN_CONFIG_REPORT_NAMES?.PartneredWholesaler
    );
   
    const refreshPage = useSelector((state: any) => state?.accounts?.refreshPage) || false;
  
    const userAccounts =
        useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
    function customComparator() {
        return 0; //means no comparing and no sorting client side
    }
    const dispatch = useAppDispatch();
    const columnItems = [
        {
            headerName: "Job#",
            headerTooltip: "Job#",
            tooltipShowDelay: 0,
            field: "jobNumber",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Return Date",
            headerTooltip: "Return Date",
            tooltipShowDelay: 0,
            field: "returnDate",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },    
       
        {
            headerName: "Customer Name",
            headerTooltip: "Customer Name",
            tooltipShowDelay: 0,
            field: "customerName",
            tooltipField: "customerName",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "DEA License",
            headerTooltip: "DEA License",
            tooltipShowDelay: 0,
            field: "deaLicense",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Manufacturer",
            headerTooltip: "Manufacturer",
            tooltipShowDelay: 0,
            field: "manufacturer",
            tooltipField: "manufacturer",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "NDC",
            headerTooltip: "NDC",
            tooltipShowDelay: 0,
            field: "ndc_Upc",
            filter: "agNumberColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Description",
            field: "description",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Strength",
            field: "strength",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Dosage Form",
            field: "dsg",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "DEA Class",
            headerTooltip: "DEA Class",
            tooltipShowDelay: 0,
            field: "deaClass",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Lot#",
            headerTooltip: "Lot#",
            tooltipShowDelay: 0,
            field: "lotNumber",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Expiration Date",
            headerTooltip: "Expiration Date",
            tooltipShowDelay: 0,
            field: "expirationDate",
            filter: "agDateColumnFilter",
            menuTabs: ["filterMenuTab"],
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ["reset"],
                debounceMs: 1000,
            },
            hide: false,
            comparator: customComparator,
            valueFormatter: function (params: any) {
                return params.value !== null && params.value !== undefined
                    ? formatDatePipe(params.value)
                    : "";
            },
        },
        {
            headerName: "Package Size",
            headerTooltip: "Package Size",
            tooltipShowDelay: 0,
            field: "packageSize",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Quantity",
            headerTooltip: "Quantity",
            tooltipShowDelay: 0,
            field: "quantity",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Full Quantity",
            field: "fullQuantity",
            filter: "agNumberColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Partial Quantity",
            field: "partialQuantity",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Unit Price",
            headerTooltip: "Unit Price",
            tooltipShowDelay: 0,
            field: "unitPrice",
            filter: "agNumberColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },

        {
            headerName: "Extended Price",
            headerTooltip: "Extended Price",
            tooltipShowDelay: 0,
            field: "extendedPrice",
            filter: "agNumberColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
            type: gridAlignment.RIGHT,
        },
        {
            headerName: "Credit Disposition",
            headerTooltip: "Credit Disposition",
            tooltipShowDelay: 0,
            field: "creditDisposition",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
        {
            headerName: "Return Reason",
            headerTooltip: "Return Reason",
            tooltipShowDelay: 0,
            field: "denialReason",
            filter: "agTextColumnFilter",
            filterParams: {
                buttons: ["reset"],
                debounceMs: 1000,
                suppressAndOrCondition: true,
            },
            menuTabs: ["filterMenuTab"],
            hide: false,
            comparator: customComparator,
        },
    ];

    const [colDefs, setColDefs] = useState(() =>
        showConfigColumns(columnItems, dbColumnConfig)
    )
    const [gridData, setGridData] = useState({
        sortOrder: "",
        rows: [],
        totalRows: 0,
        rowsPerPageOptions: [100, 200, 300],
        pageSize: 100,
        page: 1,
        globalSearch: "",
        totalCreditedERV: 0,
        totalOpenERV: 0,
        isForExcel: false,
        sortField: "",
        csvName: "OrderDetails.csv",
        URL: "Rxrd/GetOrderHistoryDetails",
        filterOptions: [],
        orderNumber: queryParams.id,
    });

    const onGridReady = (params: any) => {
        setGridApi(params.api);
    };

    const popupSave = (event: any, columnValues: any) => {
        event.preventDefault();

        setColDefs(columnValues);
        myRef?.current.handleClose();
    };

    const onSortChanged = ({
        api: { sortController },
    }: {
        api: { sortController: any };
    }): void => {
        const sortModel: any[] = sortController.getSortModel();
        if (sortModel.length > 0) {
            const { colId, sort } = sortModel[0];
            updateGridData("sortOrder", sort === "asc" ? 0 : 1);
            updateGridData("sortField", colId);
        }
    };
    const updateGridData = (k: any, v: any) =>
        setGridData((prev) => ({ ...prev, [k]: v }));

    const globalSearch = (e: any) => {
        updateGridData("globalSearch", e.target.value);
    };
    const onExport = (e: any) => {
        updateGridData("isForExcel", true);
    };
    const onClearFilter = () => {
        gridApi!.setFilterModel(null);
        updateGridData("globalSearch", "");
    };
    const paginationChange = () => {
        if (gridApi) {
            updateGridData("pageSize", gridApi.paginationGetPageSize());
        }
    };

    const partneredDetails = async () => {
        try {
            if (gridApi) {
                const dataSource = {
                    getRows: (params: any) => {
                        let data = {
                            pageNumber:
                                gridApi.paginationGetCurrentPage() === 0
                                    ? 1
                                    : gridApi.paginationGetCurrentPage() + 1,
                            pageSize: gridData.pageSize,
                            sortField: gridData.sortField,
                            sortDirection: gridData.sortOrder
                                ? parseInt(gridData.sortOrder)
                                : 1,
                            isForExcel: gridData.isForExcel,
                            globalSearch: gridData.globalSearch,
                            filterOptions: getTableFields(params.request.filterModel),
                            CustomerIds: formateIds(userAccounts),
                            orderNumber: queryParams.id,
                            //returnProgram: PartneredRow?.returnProgram,
                        };
                        //updateGridData('returnProgram', PartneredRow?.returnProgram);
                        updateGridData(
                            "filterOptions",
                            getTableFields(params.request.filterModel)
                        );
                        dispatch(getRXRDOrderHistoryDetails(data)).then((result: any) => {
                            if (result?.payload?.data?.orderLines?.length === 0) {
                                gridApi.showNoRowsOverlay();
                                params.success({
                                    rowData: [],
                                    rowCount: 0,
                                });
                            } else {
                                gridApi!.hideOverlay();
                                params.success({
                                    rowData: result?.payload?.data?.orderLines,
                                    rowCount: result?.payload?.data?.totalCount,
                                });
                            }
                            updateGridData("totalRows", result?.payload?.data?.totalCount);
                            
                            setPartneredSummaries(result?.payload?.data?.orderDetails);
                        });
                    },
                };
                gridApi.setGridOption("serverSideDatasource", dataSource);
            }
        } catch (error) { }
    };

    useEffect(() => {
        if (gridData.globalSearch) {
            const getData = setTimeout(() => {
                partneredDetails();
            }, 2000);

            return () => clearTimeout(getData);
        } else {
            partneredDetails();
        }
    }, [
        gridData.page,
        gridData.pageSize,
        gridData.sortOrder,
        gridData.sortField,
        gridData.globalSearch,
        gridApi,
        refreshPage,
    ]);

    useEffect(() => {
        if (gridData.isForExcel === true) {
            getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
            updateGridData("isForExcel", false);
        }
    }, [gridData.isForExcel]);

    useEffect(() => {
        if (Object.keys(dbColumnConfig).length > 0)
            setColDefs(showConfigColumns(columnItems, dbColumnConfig));
    }, [dbColumnConfig]);
    return (
        <Container fluid>
            <PageTitle
                name={"Order History - " + queryParams.id}
                buttonName="Back"
                routePage="/"
            ></PageTitle>
            <div className="order-history-top">
                <div className="content_body">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="highlight-section">

                                <div className="first-section d-flex flex-wrap gap-3 justify-content-between my-3">
                                    <div className="d-flex flex-column">
                                        <label>Customer Name: </label>
                                        <span className="value">
                                            {partneredSummaries?.customerName}
                                        </span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <label>DEA License: </label>
                                        <span className="value">
                                            {partneredSummaries?.deaLicense}
                                        </span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <label>Return Date: </label>
                                        <span className="value">
                                            {partneredSummaries?.returnDate}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap flex-xl-nowrap p-3 gap-4 gap-xl-0">
                                    <div className="d-flex flex-column min-width-20">
                                        <label>Piece Count</label>
                                        <span className="value">
                                            {partneredSummaries?.pieceCount}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {partneredSummaries?.isCheckStatement===1 &&

                        <Col xs={12} md={6}>
                            <div className="pdf-listing-wrapper mt-3">
                                <div className="pdf-listing">
                                    <ul>
                                        <li
                                            className="cursor_pointer"
                                            onClick={(e) => {
                                                drugFileDownload(
                                                    "Rxrd/GetCheckSummaryFile",
                                                    "CheckSummary.pdf",
                                                    queryParams.id,
                                                    null
                                                );
                                            }}
                                        >
                                            <i
                                                className="fa fa-file-pdf icon"
                                                aria-hidden="true"
                                            ></i>
                                            <a className="me-4">Check Summary</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
}
                    </Row>
                </div>
            </div>
            <div className="content_body">
                <GridComponent
                    dbUserReportId={id}
                    dbReportName={COLUMN_CONFIG_REPORT_NAMES?.RXRDOrderHistoryDetail}
                    dbColumnConfig={dbColumnConfig}
                    defaultColumns={columnItems}
                    ref={myRef}
                    gridRef={gridRef}
                    rowData={gridData.rows}
                    colDefs={colDefs}
                    onGridReady={onGridReady}
                    popupSave={popupSave}
                    paginationPageSize={gridData.pageSize}
                    paginationPageSizeSelector={gridData.rowsPerPageOptions}
                    sortChange={onSortChanged}
                    globalSearch={globalSearch}
                    onExport={onExport}
                    gridData={gridData}
                    setGridData={setGridData}
                    onClearFilter={onClearFilter}
                    paginationChange={paginationChange}
                    autoSizeStrategy={{
                        type: "fitCellContents",
                        colIds: [
                            "jobNumber",
                            "returnDate",
                            "customerName",
                            "deaLicense",
                            "manufacturer",
                            "ndc_Upc",
                            "description",
                            "strength",
                            "dsg",
                            "deaClass",
                            "lotNumber",
                            "expirationDate",
                            "packageSize",
                            "quantity",
                            "fullQuantity",
                            "partialQuantity",
                            "unitPrice",
                            "extendedPrice",
                            "creditDisposition",
                            "denialReason"
                        ],
                    }}
                />
            </div>
        </Container>
    );
};

export default RXRDOrderHistoryDetails;