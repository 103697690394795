import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "../../../../store/hooks";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import { useLocation, useNavigate } from "react-router-dom";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { toast } from "react-toastify";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  gridAlignment,
  formateIds,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import {
  getRXRDOrderHistory
} from "../../../../store/api/apiSlice";

export const RXRDOrderHistory = (props: any) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [isToast, setIsToast] = useState<any>(null);
  const refreshPage = useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const userAccounts = useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];

  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "OrderHistory.csv",
    URL: "Rxrd/GetOrderHistory",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Job#",
      headerTooltip: "Job#",
      tooltipShowDelay: 0,
      field: "jobNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      pinned: "left",
      cellRenderer: function (params: any) {
        return (
          <div
            className="text-link"
            onClick={(e) => {
              navigate(
                "/reports/rxrd-history-order-details/" + params.data.jobNumber
              );
            }}
          >
            {params.data.jobNumber}
          </div>
        );
      },
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipField: "customerName",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "DEA License",
      field: "deaLicense",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      comparator: customComparator,
    },

    {
      headerName: "Return Date",
      headerTooltip: "Return Date",
      tooltipShowDelay: 0,
      field: "returnDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },

    {
      headerName: "Piece Count",
      headerTooltip: "Piece Count",
      tooltipShowDelay: 0,
      field: "pieceCount",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },


  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.RXRDOrderHistory);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    setIsToast(true);
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];

      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    } else {
      updateGridData("sortOrder", "");
      updateGridData("sortField", "");
    }
  };
  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };

  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  const orderHistory = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: parseInt(gridData?.sortOrder || "1"),
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            updateGridData("userAccounts", userAccounts);
            dispatch(getRXRDOrderHistory(data)).then((result: any) => {
              if (result?.payload?.data?.orderHistories?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.orderHistories,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
            });
          },
        };

        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };


  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        orderHistory();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      orderHistory();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    console.log('dbColumnConfig', dbColumnConfig);
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig]);

  return (
    <Container fluid>
      <PageTitle
        name="Order History"
        buttonName="Back"
        routePage="/"
      ></PageTitle>
      <div className="content_body">

        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.RXRDOrderHistory}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}

          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "customerName",
              "deaLicense",
              "returnDate",
              "jobNumber",
              "pieceCount",
            ],
          }}
        />
      </div>
    </Container>
  );
};

export default RXRDOrderHistory;
